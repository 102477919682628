:root {
    /* 
        Defaults for primary colors if not taken from agency.
    */
    --color-primary-agency: #4085AC;
    --color-secondary-agency: #FF8100;
}


/* 
    Buying Page
*/

.buying-c-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr)); 
    gap: 3.5rem;
    margin-top: 4rem;   
}

@media (min-width: 768px) {
    .buying-c-grid {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); 
        gap: 5rem;
    }
}

.buying-c-grid-item {
    display: flex;
    flex-direction: column;
}

.buying-c-grid-item__heading {
    margin-top: 0.75rem;

    font-weight: bold;
    font-size: 1.25rem;
}

.buying-c-grid-item__description {
    margin-top: 1rem;

    font-weight: 500;
}

.buying-c-steps {
    flex-direction: column;
    align-items: center;
    counter-reset: step;
}

@media (min-width: 915px) {
    .buying-c-steps {
        flex-direction: row;
        align-items: revert;
    }
}

.buying-c-step-item {
    position: relative;
    display: flex;
    align-items: center;

    text-align: center;
}
/* 
@media (min-width: 915px) {
    .buying-c-step-item:not(:first-child)::before {
        content: '• • • •';
        margin-left: 0.5rem;
    }
} */


@media (min-width: 915px) {
    .buying-c-step-item:not(:first-child) {
        margin-left: 2rem;
    }
}

@media (min-width: 1151px) {
    .buying-c-step-item:not(:first-child) {
        margin-left: 0rem;
    }

    .buying-c-step-item:not(:first-child)::before {
        content: '• • • • •';
        margin-left: 0.5rem;
    }
}
@media (min-width: 1260px) {
    .buying-c-step-item:not(:first-child)::before {
        content: '• • • • • • • • •';
    }
}

.buying-c-step-item::after {
    counter-increment: step;
    content: counter(step);
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    font-size: 0.75rem;
    font-weight: bold;
    background-color: #FFCA03;
}

.buying-c-step-separator {
    display: block;
    margin: 1rem 0;
}

@media (min-width: 915px) {
    .buying-c-step-separator {
        display: none;
    }
}

/* Selling Page */
.selling-c-steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;

    margin-top: 4rem;
}

.selling-c-steps > * {
    flex: 0 1 350px;
}

.selling-c-hero {
    padding: 2rem;
}

@media (min-width: 520px) {
    .selling-c-hero {
        padding: 6rem;
    }
}

@media (min-width: 870px) {
    .selling-c-hero {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 14% 18%;

        background-image: url('../images/selling-hero.jpg');
        background-size: cover;
    }
}

.home-c-stat-section {
    background-image: url('../static/images/home-bg-mobile.jpg');
    background-size: cover;
}

@media (min-width: 768px) {
    .home-c-stat-section {
        background-image: url('../static/images/home-bg.jpeg');
        background-size: cover;
    }
}

/* 
    Reach UI Overrides
*/
[data-reach-menu] {
    z-index: 50;
    transform: translateY(10px);
}

[data-reach-menu-list] {
    border-radius: 0.5rem;
    border: none;
}

[data-reach-menu-item][data-selected] {
    background: var(--color-primary-agency) !important;
}

/* 
    Property Page Buttons
*/
.property-p-left-arrow {
    position: absolute;
    z-index: 1;
    top: 40%;
    left: 0;
    line-height: 1;
    padding: 0.5rem;
    margin-left: 0.75rem;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 0.375rem;
}

.property-p-right-arrow {
    position: absolute;
    z-index: 1;
    top: 40%;
    right: 0;
    line-height: 1;
    padding: 0.5rem;
    margin-right: 0.75rem;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 0.375rem;
}

/* 
    Property Card Buttons
*/
.property-c-left-arrow {
    position: absolute;
    z-index: 1;
    top: 12%;
    left: 0;
    line-height: 1;
    padding: 0.5rem;
    margin-left: 0.75rem;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 0.375rem;
}

.property-c-right-arrow {
    position: absolute;
    z-index: 1;
    top: 12%;
    right: 0;
    line-height: 1;
    padding: 0.5rem;
    margin-right: 0.75rem;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 0.375rem;
}

@media (min-width: 380px) {
    .property-c-left-arrow {
        top: 16%;
    }

    .property-c-right-arrow {
        top: 16%;
    }
}

@media (min-width: 440px) {
    .property-c-left-arrow {
        top: 18%;
    }

    .property-c-right-arrow {
        top: 18%;
    }
}

@media (min-width: 525px) {
    .property-c-left-arrow {
        top: 20%;
    }

    .property-c-right-arrow {
        top: 20%;
    }
}
@media (min-width: 616px) {
    .property-c-left-arrow {
        top: 24%;
    }

    .property-c-right-arrow {
        top: 24%;
    }
}
@media (min-width: 825px) {
    .property-c-left-arrow {
        top: 19%;
    }

    .property-c-right-arrow {
        top: 19%;
    }
}
@media (min-width: 1070px) {
    .property-c-left-arrow {
        top: 21%;
    }

    .property-c-right-arrow {
        top: 21%;
    }
}

@media (min-width: 1297px) {
    .property-c-left-arrow {
        top: 17%;
    }

    .property-c-right-arrow {
        top: 17%;
    }
}

.map-marker-pin {
    width: 25px;
    height: 41px;
    background: url('../static/images/marker-icon.png') no-repeat center/cover;
}