
.register-bg {
    background-image: url("../static/registerlogin-backdrop.jpg");
    background-size: cover;
}



.bidder-dashboard-content {
    height: calc(100vh - 4rem);
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #595959;
    opacity: 1; /* Firefox */
  }

.mini-slider .react-swipeable-view-container {
    width: 25%;
    height: 150px;
}

@media screen and (max-width: 1180px) {
    .mini-slider .react-swipeable-view-container {
        width: 33.36%;
    }
}

/* Homepage */
.property-grid {
    grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
}

@media screen and (max-width: 425px) {
    .property-grid {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
}

.watched-property-grid {
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
}

/* Bidder Complete Registration */
.complete-registration-next:hover svg {
    transform: translateX(50%);
}

@keyframes spring {

    50% {
        transform: scale(0.95, 0.95) translateY(-50%);
    }

    100% {
        transform: scale(1.0, 1.0) translateY(-50%);
    }
}

.animate-spring {
    animation: spring 250ms 1;
}

/* Manage Properties */
.back-button:hover .back-button__icon {
    transform: translateX(-50%);
}

.back-button:hover .back-button__text {
    color: #406BA6;
}

.agent-dashboard-table-action__container:hover span {
    opacity: 1;
}

.table-filter-icon {
    filter: drop-shadow(0 0 0.1rem #406BA6);
}

.table-filter__container {
    color: #375B8E;
}

.test {
    inline-size: fit-content;
}

.sticky-table {
    position: sticky;
    left: 1px;
    z-index: 100;
}

/* Agent Property More Info */
.more-info-property__description {
    max-height: 450px;
}

.more-info-docs__grid {
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));   
}

/* Agent Form Branch */
.agent-form-branch__grid {
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
}

@media screen and (min-width: 1350px) {
    .agent-form-branch__grid {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    }
}
.animate-pulse {
    animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

/* Agent dashboard navbar */
@media screen and (min-width: 1024px) {
    .navbar-collapsed {
        /* transform: translateX(4.75rem); */
        margin-left: 4.75rem;
        /* width: 95%; */
    }
}


/* Bidder dashboard navbar */

.property-page__modal {
    min-height: 250px;
    max-height: 400px;
}

.place-bid {
    background-color: #EFFAFF;
}

.place-bid__dark-text {
    color: #99D6E4;
}

/* Skeleton */
.skeleton-property-page__tabs {
    height: 400px;
}

.skeleton-home-page__cards {
    height: 620px;
}

.skeleton-home-page__img-slider {
    height: 250px;
}

/* Tooltip */ 
.tooltip__container:hover .tooltip__tooltip,
.tooltip__container:hover .tooltip__tooltip-right {
    opacity: 1;
}

/* Property Page */
.property-page__tabs {
    max-height: 300px;
}

.property-page__features {
    max-height: 150px;
    overflow-y: auto;
}


.bid-history-table {
    transition: height 500ms ease-in-out;
}

/* React Transition Group - Generic */
.fade-appear {
    opacity: 0;
}

.fade-appear-active {
    opacity: 1;
    transition: opacity 500ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}

/* React Transition Group - Navbars */
.mobile-enter, .navbar-enter {
    opacity: 0;
}

.mobile-enter-active, .navbar-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}

.mobile-exit, .navbar-exit {
    opacity: 1;
}

.mobile-exit-active, .navbar-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}


/* React Transition Group - Search Filter */
.filter-bar {
    z-index: 50;
    margin-top: 4rem;
}

.filter-bar-enter {
    z-index: 20;
    transform: translateY(-100%);
}

.filter-bar-enter-active {
    transform: translateY(0);
    transition: transform 300ms ease-in-out;
}

.filter-bar-exit {
    z-index: 20;
    transform: translateY(0);
}

.filter-bar-exit-active {
    transform: translateY(-100%);

    transition: transform 300ms ease-out;
}


.filter-collapsed-enter {
    opacity: 0;
}

.filter-collapsed-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.filter-collapsed-exit {
    opacity: 1;
}

.filter-collapsed-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.filter-section-enter {
    transform: translateY(5%);
    opacity: 0;
}

.filter-section-enter-active {
    transform: translateY(0%);
    opacity: 1;
    transition: transform 200ms ease-in-out,
                opacity 200ms ease-in-out;
}


.filter-section-exit-active {
    transform: translateY(5%);
    opacity: 0;
    transition: transform 200ms ease-in-out,
                opacity 200ms ease-in-out;
}

.backdrop-enter {
    opacity: 0;
}

.backdrop-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in-out;
}

.backdrop-exit {
    opacity: 1;
}

.backdrop-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.dashboard-navbar {
    height: calc(100vh - 4rem);
}

/* Bidder Dashbaord Property Bids Page */


@media only screen and (min-width: 768px) {
    .bidder-page {
        width: 680px;
    }
}

@media only screen and (min-width: 1000px) {
    .bidder-page {
        width: 900px;
    }
}

@media only screen and (min-width: 1350px) {
    .bidder-page {
        width: 1100px;
    }
}

/* Watched Properties */


.filter-sort-bar {
    top: 125px;
}